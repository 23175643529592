import { Component, Vue, Prop } from 'vue-property-decorator'
import { Matrix } from '@/Models'
import BreadCrumbModule from '@/store/moduleBreadCrumb'
@Component
export default class CardMatrix extends Vue {
  @Prop({ required: true })
  matrix!: Matrix

  showMatrix() {
    BreadCrumbModule.validateSetRoute({
      text: this.matrix.name,
      icon: '',
      link: this.$route.path,
    })
    this.$emit('showMatrix', this.matrix)
  }

  deleteMatrix() {
    this.$emit('deleteMatrix', this.matrix)
  }

  updateMatrix() {
    this.$emit('updateMatrix', this.matrix)
  }
}
