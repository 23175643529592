import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CardMatrix from '@/components/Cards/CardMatrix.vue'
import http from '@/services'
import { Matrix } from '@/Models'
import * as toast from '@/services/toast'
import Search from '@/components/Search/index.vue'
import collectionModule from '@/store/collection'
import matrixModule from '@/store/matrix'
import BreadCrumb from '@/components/BreadCrumb/BreadCrumb.vue'
import ExpandableButton from '@/components/ExpandableButton/index.vue'
import SubNav from '@/components/Nav/SubNav.vue'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    CardMatrix,
    Search,
    ExpandableButton,
    BreadCrumb,
    SubNav,
  },
})
export default class Matrices extends Vue {
  search = ''
  delete_modal_active = false
  matrix_selected = new Matrix()
  collection_id = 0
  matrix_array: Matrix[] = []

  async mounted() {
    const query = this.$router.currentRoute.query
    if ('collection_id' in query) {
      this.collection_id = Number(query.collection_id)
      collectionModule.setCollectionId(this.collection_id)
      await this.getMatrices()
    } else this.$router.push('/hoshin')
  }

  get show_modal(): string {
    return this.delete_modal_active ? 'is-active' : ''
  }

  changeActiveDeleteModal() {
    this.delete_modal_active = !this.delete_modal_active
  }

  filterItems(items: any) {
    this.matrix_array = items
  }

  async getMatrices() {
    menuModule.setLoding(true)
    try {
      const res = await http.get(
        `hoshin/collection_matrices/${this.collection_id}/`,
      )
      this.matrix_array = res.data
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
    menuModule.setLoding(false)
  }

  addMatrix() {
    matrixModule.setActionMode(false)
    matrixModule.clear()
    this.$router.push('/matrix').catch(() => console.log)
  }

  async deleteMatrix(matrix: Matrix) {
    this.matrix_selected = matrix
    this.changeActiveDeleteModal()
  }

  async confirmDeleteMatrix() {
    try {
      await http.delete('hoshin/matrix/' + this.matrix_selected.id + '/')
      this.matrix_array = this.matrix_array.filter(
        (m: Matrix) => m.id !== this.matrix_selected.id,
      )
      this.matrix_selected = new Matrix()
      matrixModule.clear()
      this.changeActiveDeleteModal()
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  async updateMatrix(matrix: Matrix) {
    matrixModule.setActionMode(true)
    matrixModule.setMatrix(matrix)
    this.$router.push('/matrix').catch(() => console.log)
  }

  showMatrix(matrix: Matrix) {
    matrixModule.setMatrix(matrix)
    this.$router.push('/show_matrix').catch(() => console.log)
  }
}
