import { Component, Vue } from 'vue-property-decorator'
import WYWG from '@/components/WYWYG/index.vue'
import TemplatesForm from '@/components/CMS/TemplatesForm/index.vue'
import http from '@/services'
import authModule from '@/store/auth'
import articleModule from '@/store/moduleArticle'
import { ArticleEndPoint } from '@/Models'
import { VueConstructor } from 'vue'
import { BModalComponent } from 'buefy/types/components'
import ArticleModal from '@/components/Modal/ArticleModal.vue'

@Component({
  components: {
    WYWG,
    TemplatesForm,
  },
})
export default class Article extends Vue {
  user_id = 0
  image: File | null = null
  html: any = null
  is_edit = false
  base_image = 'https://bulma.io/images/placeholders/256x256.png'
  article: ArticleEndPoint = {
    title: '',
    id: null,
    description: '',
    image: 'https://bulma.io/images/placeholders/256x256.png',
    url: null,
    pages: [
      {
        id: null,
        html: '',
        url: null,
      },
    ],
    creator: null,
    created_at: '',
  }
  modal: BModalComponent | null = null

  created() {
    this.html = null
    this.is_edit = false
    this.user_id = authModule.user.user_id
    if (articleModule.article_endpoint) {
      this.article = { ...articleModule.article_endpoint }
      this.html = this.article.pages[0].html
      this.is_edit = true
    } else
      this.article = {
        title: '',
        id: null,
        description: '',
        image: this.base_image,
        url: null,
        pages: [],
        creator: null,
        created_at: '',
      }
  }

  save_pages(data: any) {
    this.article.pages = data.pages
    this.modalOpen(
      ArticleModal,
      {
        action: this.is_edit ? 'edit' : 'create',
        article: this.article,
      },
      {
        'create-edit-article': this.is_edit
          ? this.update_articles
          : this.save_articles,
      },
    )
  }

  modalOpen(component: VueConstructor<Vue>, props: any, events: any) {
    this.modal = this.$buefy.modal.open({
      parent: this,
      component,
      hasModalCard: true,
      trapFocus: true,
      props,
      events,
    })
  }

  async save_articles(article: ArticleEndPoint) {
    const data = {
      article: {
        title: article.title,
        description: article.description,
        image: article.image !== this.base_image ? article.image : null,
      },
      pages: this.article.pages,
    }

    try {
      await http.post(
        `cms/articles_by_type_primary_cms/?user_id=${this.user_id}&quadrant_content_id=${articleModule.quadrant_content_id}`,
        data,
      )
      this.$buefy.toast.open({
        message: 'Articulo agregado',
        type: 'is-success',
      })

      this.$router.push('/cms')
    } catch (error: any) {
      this.$buefy.toast.open({
        message: error.response.data.detail,
        type: 'is-danger',
      })
    }
  }

  async update_articles(article: ArticleEndPoint) {
    const data = {
      article: {
        title: article.title,
        description: article.description,
        image: article.image !== this.base_image ? article.image : null,
      },
      pages: this.article.pages,
    }
    try {
      await http.put(
        `cms/articles_by_type_primary_cms/${this.article.id}/`,
        data,
      )
      this.$buefy.toast.open({
        message: 'Articulo editado',
        type: 'is-success',
      })
      this.$router.push('/cms')
    } catch (error: any) {
      this.$buefy.toast.open({
        message: error.response.data.detail,
        type: 'is-danger',
      })
    }
  }
}
